<template>
  <v-container id="settings" fluid tag="section">
    <v-row>
      <!-- <v-col cols="6">
        <v-card class="fill-height py-6" elevation="1" shaped>
          <v-card-title>
            Inbound Api Management
            <v-spacer></v-spacer>

            <v-btn
              :to="{ name: 'api-management' }"
              :color="'primary'"
              exact
              large
              icon
            >
              <v-icon large> mdi-view-carousel </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col> -->
      <v-col v-if="currentUserRole == 'admin'" cols="6">
        <v-card shaped class="fill-height py-6" elevation="1">
          <v-card-title>
            Inbound Api Management
            <v-spacer></v-spacer>
            <v-btn
              @click="inboundApiManagementDialog = true"
              :color="'primary'"
              large
              icon
            >
              <v-icon large> mdi-view-carousel </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col v-if="currentUserRole == 'admin'" cols="6">
        <v-card shaped class="fill-height py-6" elevation="1">
          <v-card-title>
            Outbound Api Management
            <v-spacer></v-spacer>
            <v-btn
              @click="apiManagementDialog = true"
              :color="'primary'"
              large
              icon
            >
              <v-icon large> mdi-view-carousel-outline </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col v-if="currentUserRole == 'admin'" cols="6">
        <v-card shaped class="fill-height py-6" elevation="1">
          <v-card-title>
            Application Source Management
            <v-spacer></v-spacer>
            <v-btn
              @click="applicationSourceManagementDialog = true"
              :color="'primary'"
              large
              icon
            >
              <v-icon large> mdi-source-branch </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col v-if="currentUserRole == 'admin'" cols="6">
        <v-card
          @click="globalSettingsDialog = true"
          class="fill-height py-6"
          elevation="1"
          shaped
        >
          <v-card-title>
            Global Settings
            <v-spacer></v-spacer>

            <v-btn :color="'primary'" large icon>
              <v-icon large> mdi-tune-variant </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          @click="userSettingsDialog = true"
          class="fill-height py-6"
          elevation="1"
          shaped
        >
          <v-card-title>
            Auto Launch Settings
            <v-spacer></v-spacer>

            <v-btn :color="'primary'" large icon>
              <v-icon large> mdi-tune-variant </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="fill-height py-6" elevation="1" shaped>
          <v-card-title>
            Messaging Console
            <v-spacer></v-spacer>

            <v-btn
              :to="{ name: 'messaging-console' }"
              :color="'primary'"
              exact
              large
              icon
            >
              <v-icon large> mdi-message-cog-outline </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <inboundApiManagementDialog
      v-if="inboundApiManagementDialog"
      :dialogModel="inboundApiManagementDialog"
      :selectedModel="null"
      @closeDialog="inboundApiManagementDialog = false"
      @created="inboundApiManagementDialog = false"
    />
    <applicationSourceManagementDialog
      v-if="applicationSourceManagementDialog"
      :dialogModel="applicationSourceManagementDialog"
      :selectedModel="null"
      @closeDialog="applicationSourceManagementDialog = false"
      @created="applicationSourceManagementDialog = false"
    />
    <apiManagementDialog
      v-if="apiManagementDialog"
      :dialogModel="apiManagementDialog"
      :selectedModel="selectedApiDetails"
      @closeDialog="apiManagementDialog = false"
      @created="apiManagementDialog = false"
    />
    <globalSettingsDialog
      v-if="globalSettingsDialog"
      :dialogModel="globalSettingsDialog"
      :selectedModel="null"
      @closeDialog="globalSettingsDialog = false"
      @created="globalSettingsDialog = false"
    />
    <userSettingsDialog
      v-if="userSettingsDialog"
      :dialogModel="userSettingsDialog"
      :selectedModel="null"
      @closeDialog="userSettingsDialog = false"
      @created="userSettingsDialog = false"
    />
  </v-container>
</template>

<script>
export default {
  components: {
    inboundApiManagementDialog: () =>
      import("@/components/dialogs/inboundApiManagementDialog"),
    applicationSourceManagementDialog: () =>
      import("@/components/dialogs/applicationSourceManagementDialog"),
    apiManagementDialog: () =>
      import("@/components/dialogs/apiManagementDialog"),
    globalSettingsDialog: () =>
      import("@/components/dialogs/globalSettingsDialog"),
    userSettingsDialog: () => import("@/components/dialogs/userSettingsDialog"),
  },
  data: () => ({
    inboundApiManagementDialog: false,
    applicationSourceManagementDialog: false,
    apiManagementDialog: false,
    globalSettingsDialog: false,
    userSettingsDialog: false,
  }),
  computed: {
    isQuoteAutoEmailingEnabled() {
      return this.$store.getters["auth/user_settings"]
        ?.is_quote_complete_auto_emailing_enabled;
    },
    selectedApiDetails() {
      return null;
    },
  },
  methods: {
    async toggleUserQuoteAutoEmailing() {
      await this.$store.dispatch("auth/toggleUserQuoteAutoEmailing");
      return true;
    },
  },
};
</script>
